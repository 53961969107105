import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label , Color } from 'ng2-charts';


@Component({
  selector: 'app-advanced-mode-graph-model',
  templateUrl: './advanced-mode-graph-model.component.html',
  styleUrls: ['./advanced-mode-graph-model.component.scss']
})
export class AdvancedModeGraphModelComponent implements OnInit {
  
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { 
      xAxes: [{}],
      yAxes: [{}] 
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartLabels: Label[] = 
  [
    'Lanco Infratech',
    'Nilkamal Plastics',
    'Sobha Ltd.',
    'The Himalaya Drug Company',
    'Exide Industries',
    'Guptas sons pvt. ltd.',
    'Manglas sons pvt. ltd.',
    'Ballarpur Industries Limited',
    'Lakshmi Machine Works',
    'POSCO Indias'
  ];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [55000, 100000, 85000, 76000, 96000, 56000, 25000, 75000, 100000, 54000], label: 'Distributors' },
  ];

  data:any=[];
dataNotFound=false
reqSent=false
  
  constructor() { }
  
  
  ngOnInit() {
    this.data = [
      {
        "firm":"Lanco Infratech",
        "ordervalue": "55,000",
        "assign": "Rahul Tyagi",
      },
      {
        "firm":"Nilkamal Plastics",
        "ordervalue": "1,00,000",
        "assign": "Sumit Rawat",
      },
      {
        "firm":"Sobha Ltd.",
        "ordervalue": "85,000",
        "assign": "Ajay Nain",
      },
      {
        "firm":"The Himalaya Drug Company",
        "ordervalue": "76,000",
        "assign": "Rahul Tyagi",
      },
      {
        "firm":"Exide Industries",
        "ordervalue": "96,000",
        "assign": "Sumit Rawat",
      },
      {
        "firm":"Gupta's sons pvt. ltd.",
        "ordervalue": "56,000",
        "assign": "Ajay Nain",
      },
      {
        "firm":"Mangla's sons pvt. ltd.",
        "ordervalue": "25,000",
        "assign": "Kapil Singla",
      },
      {
        "firm":"Ballarpur Industries Limited",
        "ordervalue": "75,000",
        "assign": "Suraj Kumar",
      },
      {
        "firm":"Lakshmi Machine Works",
        "ordervalue": "1,00,000",
        "assign": "Vipin Sharma",
      },
      {
        "firm":"POSCO Indias",
        "ordervalue": "54,000",
        "assign": "A.P. Singh",
      },
    ]
  }
  

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }


  public barChartColors: Color[] = [
    { backgroundColor: '#0288D1' },
  ]


  
}
