import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProductDetailModelComponent } from 'src/app/product/product-detail-model/product-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { LeadStatusModelComponent } from 'src/app/leads/detail/lead-status-model/lead-status-model.component';

@Component({
  selector: 'app-dr-list',
  templateUrl: './dr-list.component.html',
  // styleUrls: ['./dr-list.component.scss']
})
export class DrListComponent {

  data: any = [];
  dataNotFound = false
  reqSent = false

  checkModulePermission:any = {};
  type: any
  typeName: any
  constructor(
    public activatedroute: ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    public db: DatabaseService,
    private render: Renderer2,
    public event: CustomEventsService
  ) {
    this.event.subscribe('refresh-dr-list', (data: any) => {
      // this.type =this.activatedroute.snapshot.params.type
      this.todayDate = moment(new Date()).format('YYYY-MM-DD')
      this.getData();
      console.log('refresh');

    })
  }
  ngOnInit() {
    this.todayDate = moment(new Date()).format('YYYY-MM-DD')
    this.getData();
  }
  todayDate: any
  pageNo: any = 1;
  tempPageNo = 1;
  totalPages: any
  start: any = 0;
  limit: any = 50;
  totalLength: any;
  filter: any = {};
  routes:any
  getData() {
    this.dataNotFound = false;
    this.reqSent = false;
    this.type = this.activatedroute.snapshot.params.type
    this.typeName = this.type == 10 ? 'Retailer' : 'Distributor'
    this.filter.cust_type_id = this.type
    this.routes = this.type == 10 ? 'dr-list/10' : this.type == 1 ? 'dr-list/1' : 'dr-list';
    this.checkModulePermission = this.db.loggedInPermission(this.routes,0)

    console.log("hello next permisio",this.checkModulePermission);
    
    this.filter.is_lead = "0";
    let reqData = {
      limit: this.limit,
      start: this.start,
      filter: this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'dis_network/list').subscribe(resp => {
      this.reqSent = true;
      if (resp['status'] == 'success') {
        this.data = resp['result'].data;
        if (!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  pagination(action) {

    if (action == 'pageNo') {
      if (this.pageNo > 0 && this.pageNo <= this.totalPages) {
        this.start = this.limit * (this.pageNo - 1)
      } else {
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if (action == 'next') {

      if (this.totalLength == (this.start + this.data.length)) return;

      this.start = this.start + this.limit
      this.pageNo++;
    } else {
      if (this.pageNo == 1) return
      this.start = this.start - this.limit
      this.pageNo--;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh() {
    this.start = 0;
    this.limit = 50;
    this.filter = {};
    this.getData();
  }
  delete(data) {
    swal({
      title: "Are you sure?",
      text: "You want to delete this " + this.typeName + "!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.db.presentLoader();
          data.status = data.status == 0 ? 1 : 0;
          data.customer_id = data.encrypt_id;
          this.db.postReq(data, 'dis_network/delete').subscribe(resp => {
            if (resp['status'] == 'success') {
              swal(data.status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
            } else {
              if (resp['message'] == 'Invalid token') {
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'], resp['message'])
            }
          })

        } else {
          swal("Your data is safe!");
        }
      });
  }
  backClicked() {
    this._location.back();
  }
  status: boolean = false

  toggleNav() {
    this.status = !this.status;
    if (this.status) {
      this.render.addClass(document.body, 'toggle-active-tlp');
    }
    else {
      this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }

  openStatusDialog(data): void {
    const dialogRef = this.dialog.open(LeadStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: data
    });
    
    dialogRef.afterClosed().subscribe(result => {
      // this.getCounters();
      this.getData();
      // this.customEvent.publish('refresh-lead-detail',{});
    });
  }
  downloadExcel(){
    let reqData = {
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/list/excel').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
  }
}
