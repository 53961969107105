import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-referral-edit-model',
  templateUrl: './referral-edit-model.component.html'
})
export class ReferralEditModelComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public db:DatabaseService,public actRoute:ActivatedRoute,public matdialog:MatDialog) { }
  
  ngOnInit() {
    this.formsInit(this.data);

  }
  getDetail(){
    this.db.presentLoader();
    
    this.db.postReq({},'master/referral_data_list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['result']['data']);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      id : [data.id,[Validators.required]],
      welcome_point : [data.welcome_point,[Validators.required]],
      birthday_points : [data.birthday_points,[Validators.required]],
      registeration_referral_point : [data.registeration_referral_point,[Validators.required]],
      retailer_sales_user_point : [data.retailer_sales_user_point,[Validators.required]],
      retailer_distributor_point : [data.retailer_distributor_point,[Validators.required]],
      // referral_user_point : [data.referral_user_point,[Validators.required]],
      // referral_owner_point : [data.referral_owner_point,[Validators.required]],
      // min_gift_redeem : [data.min_gift_redeem,[Validators.required]],
      status : [1,[Validators.required]]
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    console.log(this.form.value);
    let reqData= this.form.value
    this.db.presentLoader();
    this.db.postReq(reqData,'master/referral_update').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.matdialog.closeAll();
        this.db.successAlert(resp['status'],resp['message']);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
}
