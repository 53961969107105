import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatDialog } from '@angular/material';
import { CustomEventsService } from 'src/app/_services/custom-events.service';


@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html'
})
export class UserHeaderComponent implements OnInit {
  status:boolean = false
  constructor(public customEvent:CustomEventsService,public dialog:MatDialog,private render: Renderer2,private _location: Location,public activatedroute:ActivatedRoute,public db:DatabaseService) { 
    customEvent.subscribe('refresh-lead-detail',resp=>{
      this.getDetail(this.activatedroute.snapshot.params.id)
    })
  }
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  
  toggleNav() 
  {
    this.status = !this.status;
    if(this.status) {
      this.render.addClass(document.body, '');
    }
    else {
      this.render.removeClass(document.body, '');
    }
  }
  
  data:any={};
  getDetail(id){
    console.log('getDetail', id);
    
    this.db.presentLoader();
    
    this.db.postReq({'user_id':id},'master/sales_user/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data'];   
        console.log(this.data);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  
  
  backClicked() {
    this._location.back();
    // location.reload();
    this.status = !this.status;
    if(this.status) {
      this.render.removeClass(document.body, 'toggle-active-tlp');
    }
    else {
      this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
  
}
