import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { OrderDetailStatusComponent } from '../order-detail-status/order-detail-status.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent implements OnInit {
  status:boolean = false
  
  constructor(public dialog : MatDialog, private render: Renderer2,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
    
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'order_id':id},'order/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['result'];   
        console.log(this.data);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  toggleNav() 
  {
    this.status = !this.status;
    if(this.status) {
      this.render.addClass(document.body, 'toggle-active-tlp');
    }
    else {
      this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
  
  
  openInvoiceDetail(): void {
    // const dialogRef = this.dialog.open(InvoiceModelComponent, {
    //   width: '768px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  
  
  
  openInvoiceItem(): void {
    // const dialogRef = this.dialog.open(InvoiceItemModelComponent, {
    //   width: '768px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  
  openOrderStatusUpdate(): void {
    const dialogRef = this.dialog.open(OrderDetailStatusComponent, {
      width: '400px',
      autoFocus: false,
      data: {data:this.data}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getDetail(this.activatedroute.snapshot.params.id)    });
      
    }
    
    
  }
  