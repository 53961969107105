import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  checkModulePermission:any = {};
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public render:Renderer2,
    public activatedroute: ActivatedRoute,
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.checkModulePermission = this.db.loggedInPermission(9,this.activatedroute.snapshot.routeConfig.path)

      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'master/sales_user').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          console.log(this.data);
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      console.log(action);
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        if(this.totalLength == (this.start+this.data.length)) return;
        
        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: "You want to delete this",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          // data.status = data.status == 0 ? 1 : 0;
          data.user_id = data.id;
          this.db.postReq(data,'master/system_user/delete').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal("Deleted", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    statusHandler(data){
      swal({
        title: "Are you sure?",
        text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          let reqData = {
            status : data.status == 0 ? 1 : 0,
            user_id : data.id
          }
          // data.status = data.status == 0 ? 1 : 0;
          // data.user_id = data.id;
          this.db.postReq(reqData,'master/sales_user/update').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(data.status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }
    openDetail(row): void {
      // console.log(row);
      
      // const dialogRef = this.dialog.open(RoleDetailModelComponent, {
      //   width: '768px',
      //   autoFocus: false,
      //   data: row
      // });
      
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
      // });
    }
     
  status:boolean = false
  toggleNav() {
    console.log('toggleNav');
    
    this.status = !this.status;
    if(this.status) {
      this.render.removeClass(document.body, 'toggle-active-tlp');
    }
    else {
        this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
  downloadExcel(){
    let reqData = {
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/sales_user/excel').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
  }
  }
  