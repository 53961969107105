import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { RemarkModelComponent } from 'src/app/remark-model/remark-model.component';
import * as moment from 'moment';
import { GiftStatusModelComponent } from 'src/app/redeem-req/gift-status-model/status-model.component';
import { RedeemRequestStatusModelComponent } from 'src/app/redeem-req/redeem-status-model/status-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { ExpenseStatusModelComponent } from 'src/app/expense/status-modal/status-model.component';
import { TravelStatusModelComponent } from '../status-modal/status-model.component';

@Component({
  selector: 'app-travel-list',
  templateUrl: './travel-list.component.html'
})
export class TravelListComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false
  
  
  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    ) {  
      
    }
    ngOnInit() {
      this.db.getStates();
     
      this.getCounters();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    }
    tabActiveType:any={};
    tabActive:any='All'
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      this.filter.status = this.tabActive;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'travel_plan/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    counters:any=[];
    getCounters(){
      
      this.db.presentLoader();
      this.db.postReq({},'travel_plan/list/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          // resp['result'].data.map(r=>{
          this.counters= resp['result'].data
          // })
          console.log(this.counters);
          // this.tabActive = resp['result'].data[0].name; 
          this.getData();
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
   
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    openStatusDialog(data:any): void {
      console.log('openStatusDialog',data);
      // this.db.redeemRequestData = data;
      const dialogRef = this.dialog.open(TravelStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getCounters();     
      });
    }
    openGiftDialog(data): void {
      console.log(data);
      // this.db.redeemRequestData = data;
      const dialogRef = this.dialog.open(GiftStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getCounters();     
      });
    }
  
    downloadExcel(){
      this.filter.gift_status = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'travel_plan/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
  }
  
  
  
  