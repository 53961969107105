import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-viewproductimage-model',
  templateUrl: './viewproductimage-model.component.html',
  styleUrls: ['./viewproductimage-model.component.scss']
})
export class ViewproductimageModelComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,public db:DatabaseService,
    private _location: Location) { }
  ngOnInit() {
    console.log(this.data,'view image data')
  }
  openUrl(src){
    // console.log(this.data,'view image data')
    window.open(src,'_blank')
  }

}
