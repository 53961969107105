import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-gift-gallery-add',
  templateUrl: './gift-gallery-add.component.html'
})
export class GiftGalleryAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'gift_id':id},'gift/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){

        resp['result']['data']['status'].toString();
        this.formsInit(resp['result']['data']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  image_path_loading:any
  form: FormGroup;
  changeListener($event): void {
    console.log($event);
    this.image_path_loading = true;
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.controls['image_filename'].setValue(resp['response'].fileName);
          this.image_path_loading=false;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.image_path_loading = false;
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  distributor_gift_checked: boolean = false;
  retailer_gift_checked: boolean = false;
  sales_user_gift_checked: boolean = false;
  formsInit(data:any) {
    console.log('data -->', data);
    this.form = this.db.formBuilder.group({
      title: [data.title ? data.title : null ,[Validators.required]],
      description: [data.description ? data.description : null , [Validators.required]],
      image_filename: [data.image_filename ? data.image_filename : null , [Validators.required]],
      points: [data.points ? data.points : null,[Validators.required]],
      user_type :[data.user_type ? data.user_type : '' ,[]],
      status :[this.actRoute.snapshot.params.id ? data.status.toString() : "1" ,[Validators.required]],
      distributor_gift :[this.actRoute.snapshot.params.id ? data.distributor_gift == 1 ? true : false : false ,[Validators.required]],
      retailer_gift :[this.actRoute.snapshot.params.id ? data.retailer_gift == 1 ? true : false : false ,[Validators.required]],
      sales_user_gift :[this.actRoute.snapshot.params.id ? data.sales_user_gift == 1 ? true : false : false ,[Validators.required]],
      gift_id: [this.actRoute.snapshot.params.id ? this.actRoute.snapshot.params.id : null,[]],
    })
    this.distributor_gift_checked = this.form.value.distributor_gift;
    this.retailer_gift_checked = this.form.value.retailer_gift;
    this.sales_user_gift_checked = this.form.value.sales_user_gift;
    console.log('distributor_gift_checked',this.distributor_gift_checked, 'retailer_gift_checked', this.retailer_gift_checked, 'sales_user_gift_checked', this.sales_user_gift_checked);
    console.log('this.form',this.form);
    
  }
  
  onSubmit(){
    
    console.log(this.form.value);
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'gift/update' : 'gift/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
