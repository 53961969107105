import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementAddComponent } from './announcement/announcement-add/announcement-add.component';
import { AnnouncementListComponent } from './announcement/announcement-list/announcement-list.component';
import { AttandanceListComponent } from './attandance-list/attandance-list.component';
import { BannerAddComponent } from './banner/add/add.component';
import { BannerListComponent } from './banner/list/list.component';
import { BrandAddComponent } from './brand/brand-add/brand-add.component';
import { BrandListComponent } from './brand/brand-list/brand-list.component';
import { CheckInListComponent } from './check-in-list/check-in-list.component';
import { CouponAddComponent } from './coupon/coupon-add/add.component';
import { CuponCodeListComponent } from './coupon/cupon-code-list/cupon-code-list.component';
// import { ProductCategoryAddComponent } from './product-group/product-category-list/product-category-add.component';
// import { ProductCategoryListComponent } from './product-group/product-category-list/product-category-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrandAssignedListComponent } from './dr-network/brands-assigned/brand-assigned-list.component';
import { DrAddComponent } from './dr-network/dr-add/dr-add.component';
import { DrDetailComponent } from './dr-network/dr-detail/dr-detail.component';
import { DrInvoicesComponent } from './dr-network/dr-invoices/dr-invoices.component';
import { DrListComponent } from './dr-network/dr-list/dr-list.component';
import { DrOrdersComponent } from './dr-network/dr-orders/dr-orders.component';
import { DrProductDiscountsComponent } from './dr-network/dr-product-discounts/dr-product-discounts.component';
import { ExpenseAddComponent } from './expense/expense-add/expense-add.component';
import { ExpenseListComponent } from './expense/expense-list/expense-list.component';
import { FaqAddComponent } from './faq/add/add.component';
import { FaqListComponent } from './faq/list/list.component';
import { FeedBackListComponent } from './feedback/list/list.component';
import { GiftDetailComponent } from './gift-gallery/gift-detail/gift-detail.component';
import { GiftGalleryAddComponent } from './gift-gallery/gift-gallery-add/gift-gallery-add.component';
import { GiftListComponent } from './gift-gallery/list/list.component';
import { HsnCodeAddComponent } from './hsn-code/hsn-code-add/hsn-code-add.component';
import { HsnCodeListComponent } from './hsn-code/hsn-code-list/hsn-code-list.component';
import { InvoiceAddComponent } from './invoice/invoice-add/invoice-add.component';
import { InvoiceDetailComponent } from './invoice/invoice-detail/invoice-detail.component';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { DealerBasicComponent } from './leads/detail/dealer-basic/dealer-basic.component';
import { DealerCouponListComponent } from './leads/detail/dealer-coupon-list/dealer-coupon-list.component';
import { DealerPointsListComponent } from './leads/detail/dealer-points-list/dealer-points-list.component';
import { DealerRedeemListComponent } from './leads/detail/dealer-redeem-list/dealer-redeem-list.component';
import { LeadAddComponent } from './leads/lead-add/lead-add.component';
import { LeadListComponent } from './leads/lead-list/list.component';
import { LeaveListComponent } from './leave-list/leave-list.component';
import { LoginComponent } from './login/login.component';
import { NewsAddComponent } from './news/add/add.component';
import { NewsListComponent } from './news/list/list.component';
import { NotificationsAddComponent } from './notifications/add/add.component';
import { NotificationsListComponent } from './notifications/list/list.component';
import { OfferAddComponent } from './offer/add/add.component';
import { OfferDetailComponent } from './offer/detail/detail.component';
import { OfferListComponent } from './offer/list/list.component';
import { OrderAddComponent } from './order/order-add/order-add.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { PaymentComponent } from './payment/payment/payment.component';
import { PostalAddComponent } from './postal-master/postal-add/postal-add.component';
import { PostalMasterListComponent } from './postal-master/postal-master-list/postal-master-list.component';
import { ProductDiscountComponent } from './product-discount/product-discount.component';
import { ProductCategoryAddComponent } from './product-category/product-category-add/product-category-add.component';
import { ProductCategoryListComponent } from './product-category/product-category-list/product-category-list.component';
import { ProductUnitAddComponent } from './product-unit/product-unit-add/product-unit-add.component';
// import { ProductUnitDetailComponent } from './product-unit/product-unit-detail/product-unit-detail.component';
import { ProductUnitListComponent } from './product-unit/product-unit-list/product-unit-list.component';
import { ProductAddComponent } from './product/product-add/product-add.component';
// import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { ProductListComponent } from './product/product-list/product-list.component';

import { PurchaseOrderAddComponent } from './purchase-order/purchase-order-add/purchase-order-add.component';
import { PurchaseOrderDetailComponent } from './purchase-order/purchase-order-detail/purchase-order-detail.component';
import { PurchaseOrderListComponent } from './purchase-order/purchase-order-list/purchase-order-list.component';
import { RedeemDetailComponent } from './redeem-req/redeem-detail/redeem-detail.component';
import { RedeemReqListComponent } from './redeem-req/redeem-req-list/redeem-req-list.component';
import { ReferralListComponent } from './referel/referral-list/referral-list.component';
import { ProductGroupStockReportComponent } from './reports/product-group-report/product-group-report.component';
import { SalesReportComponent } from './reports/sales-report/sales-report.component';
import { StockReportComponent } from './reports/stock-report/stock-report.component';
import { siteDetailComponent } from './site/detail/detail.component';
import { SiteListComponent } from './site/site-list/list.component';
import { SkeletonAddComponent } from './skeleton/skeleton-add/skeleton-add.component';
import { SkeletonDetailComponent } from './skeleton/skeleton-detail/skeleton-detail.component';
import { SkeletonListComponent } from './skeleton/skeleton-list/skeleton-list.component';
import { TravelAddComponent } from './travel/travel-add/travel-add.component';
import { TravelListComponent } from './travel/travel-list/travel-list.component';
import { RoleAddComponent } from './user-role-master/role-add/role-add.component';
import { RoleListComponent } from './user-role-master/role-list/role-list.component';
import { UsersAddComponent } from './users/users-add/users-add.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { PoComponent } from './vendor/po/po.component';
import { VendorAddComponent } from './vendor/vendor-add/vendor-add.component';
import { VendorDetailComponent } from './vendor/vendor-detail/vendor-detail.component';
import { VendorListComponent } from './vendor/vendor-list/vendor-list.component';
import { VideoListComponent } from './videos/video-list/list.component';
import { AuthGuard } from './_guard/authGuard';
import { TempUsersListComponent } from './temp-users/temp-users.component';
import { UserAddComponent } from './sales-user/user-add/user-add.component';
import { UserDetailComponent } from './sales-user/user-detail/user-detail.component';
import { UserListComponent } from './sales-user/user-list/user-list.component';
import { UsCheckinsListComponent } from './sales-user/us-checkins-list/us-checkins-list.component';
import { UsDistributorsLisComponent } from './sales-user/us-distributors-lis/us-distributors-lis.component';
import { UsExpenseListComponent } from './sales-user/us-expense-list/us-expense-list.component';
import { UsFollowupListComponent } from './sales-user/us-followup-list/us-followup-list.component';
import { UsLeadListComponent } from './sales-user/us-lead-list/us-lead-list.component';
import { UsLeaveListComponent } from './sales-user/us-leave-list/us-leave-list.component';
import { UsPaymentListComponent } from './sales-user/us-payment-list/us-payment-list.component';
import { UsPrimaryOrderListComponent } from './sales-user/us-primary-order-list/us-primary-order-list.component';
import { UsSecondaryOrderListComponent } from './sales-user/us-secondary-order-list/us-secondary-order-list.component';
import { UsTargetListComponent } from './sales-user/us-target-list/us-target-list.component';
import { UsTravelListComponent } from './sales-user/us-travel-list/us-travel-list.component';
import { FollowupListComponent } from './followup-list/followup-list.component';
import { ExpenseDetailComponent } from './expense/expense-detail/expense-detail.component';
import { TravelDetailComponent } from './travel/travel-detail/travel-detail.component';
import { PopListComponent } from './pop/list/list.component';
import { EnquiryListComponent } from './enquiry/list/list.component';
import { PopAddComponent } from './pop/add/add.component';
import { EventListComponent } from './event-plan/event-list/event-list.component';
import { DealerFollowupListComponent } from './leads/detail/dealer-followup-list/dealer-followup-list.component';
import { DealerCheckinListComponent } from './leads/detail/dealer-checkin-list/dealer-checkin-list.component';
import { PopDetailComponent } from './pop/detail/detail.component';
import { EventDetailComponent } from './event-plan/detail/detail.component';
import { HolidayListComponent } from './holiday/list/list.component';
import { HolidayAddComponent } from './holiday/add/add.component';
import { NotificationDetailComponent } from './notifications/detail/detail.component';
import { AllowanceListComponent } from './allowance-list/allowance-list.component';
import { AttendanceDetailComponent } from './attandance-list/attendance-detail/attendance-detail.component';
import { ContentAddComponent } from './content/add/add.component';
import { ContentListComponent } from './content/list/list.component';
import { ProductGroupAddComponent } from './product-group/product-group-add/product-group-add.component';
import { ProductGroupListComponent } from './product-group/product-group-list/product-group-list.component';
import { LiveTrackComponent } from './live-track/live-track.component';
import { LiveTrackDetailComponent } from './live-track-detail/live-track-detail.component';
import { ProductDivisonAddComponent } from './product-divison/product-divison-add/product-divison-add.component';
import { ProductDivisonListComponent } from './product-divison/product-divison-list/product-divison-list.component';
import { ProductSizeListComponent } from './product-size/product-size-list/product-size-list.component';
import { ProductSizeAddComponent } from './product-size/product-size-add/product-size-add.component';
import { BeatPlanAddComponent } from './beat-plan/beat-plan-add/beat-plan-add.component';
import { BeatPlanListComponent } from './beat-plan/beat-plan-list/beat-plan-list.component';
import { BeatPlanDetailPageComponent } from './beat-plan/beat-plan-detail-page/beat-plan-detail-page.component';
import { ProductColourAddComponent } from './product-colour/product-colour-add/product-colour-add.component';
import { ProductColourListComponent } from './product-colour/product-colour-list/product-colour-list.component'
import { DrDistributorsLisComponent } from './dr-network/dr-distributors-lis/dr-distributors-lis.component';
import { UsPointsListComponent } from './sales-user/us-points-list/us-points-list.component';
import { DashboardLmsComponent } from './dashboard-lms/dashboard-lms.component';

const routes: Routes = [

  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardLmsComponent, canActivate: [AuthGuard] },
  { path: 'product-list', component: ProductListComponent, canActivate: [AuthGuard] },
  { path: 'product-edit/:id', component: ProductAddComponent, canActivate: [AuthGuard] },
  { path: 'product-add', component: ProductAddComponent, canActivate: [AuthGuard] },
  // { path: 'product-detail', component: ProductDetailComponent, canActivate: [AuthGuard]},
  { path: 'product-color-add', component: ProductColourAddComponent, canActivate: [AuthGuard] },
  { path: 'product-color-edit/:id', component: ProductColourAddComponent, canActivate: [AuthGuard] },
  { path: 'product-color-list', component: ProductColourListComponent, canActivate: [AuthGuard] },
  { path: 'product-unit-list', component: ProductUnitListComponent, canActivate: [AuthGuard] },
  { path: 'product-unit-add', component: ProductUnitAddComponent, canActivate: [AuthGuard] },
  { path: 'product-unit-edit/:id', component: ProductUnitAddComponent, canActivate: [AuthGuard] },
  { path: 'brand-list', component: BrandListComponent, canActivate: [AuthGuard] },
  { path: 'brand-add', component: BrandAddComponent, canActivate: [AuthGuard] },
  { path: 'brand-edit/:id', component: BrandAddComponent, canActivate: [AuthGuard] },
  { path: 'product-category-list', component: ProductCategoryListComponent, canActivate: [AuthGuard] },
  { path: 'product-category-add', component: ProductCategoryAddComponent, canActivate: [AuthGuard] },
  { path: 'product-category-edit/:id', component: ProductCategoryAddComponent, canActivate: [AuthGuard] },
  { path: 'product-divison-list', component: ProductDivisonListComponent, canActivate: [AuthGuard] },
  { path: 'product-divison-add', component: ProductDivisonAddComponent, canActivate: [AuthGuard] },
  { path: 'product-divison-edit/:id', component: ProductDivisonAddComponent, canActivate: [AuthGuard] },
  { path: 'product-size-list', component: ProductSizeListComponent, canActivate: [AuthGuard] },
  { path: 'product-size-add', component: ProductSizeAddComponent, canActivate: [AuthGuard] },
  { path: 'product-size-edit/:id', component: ProductSizeAddComponent, canActivate: [AuthGuard] },
  { path: 'product-group-list', component: ProductGroupListComponent, canActivate: [AuthGuard] },
  { path: 'product-group-add', component: ProductGroupAddComponent, canActivate: [AuthGuard] },
  { path: 'product-group-edit/:id', component: ProductGroupAddComponent, canActivate: [AuthGuard] },
  { path: 'postal-master-list', component: PostalMasterListComponent, canActivate: [AuthGuard] },
  { path: 'postal-master-add', component: PostalAddComponent, canActivate: [AuthGuard] },
  { path: 'postal-master-edit/:id', component: PostalAddComponent, canActivate: [AuthGuard] },
  { path: 'hsn-code-list', component: HsnCodeListComponent, canActivate: [AuthGuard] },
  { path: 'hsn-code-add', component: HsnCodeAddComponent, canActivate: [AuthGuard] },
  { path: 'hsn-code-edit/:id', component: HsnCodeAddComponent, canActivate: [AuthGuard] },
  { path: 'dr-list/:type', component: DrListComponent, canActivate: [AuthGuard] },
  // { path: 'distributor-list', component: DrListComponent, canActivate: [AuthGuard] },
  // { path: 'retailer-list', component: DrListComponent, canActivate: [AuthGuard] },
  { path: 'dr-add/:type', component: DrAddComponent, canActivate: [AuthGuard] },
  { path: 'dr-edit/:id', component: DrAddComponent, canActivate: [AuthGuard] },
  { path: 'dr-detail/:id', component: DrDetailComponent, canActivate: [AuthGuard] },
  { path: 'dr-brands/:type/:id', component: BrandAssignedListComponent, canActivate: [AuthGuard] },
  { path: 'dr-orders/:type/:id', component: DrOrdersComponent, canActivate: [AuthGuard] },
  { path: 'dr-product-discounts/:type/:id', component: DrProductDiscountsComponent, canActivate: [AuthGuard] },
  { path: 'dr-invoices/:type/:id', component: DrInvoicesComponent, canActivate: [AuthGuard] },
  { path: 'vendor-po/:id', component: PoComponent, canActivate: [AuthGuard] },
  { path: 'vendor-list', component: VendorListComponent, canActivate: [AuthGuard] },
  { path: 'vendor-add', component: VendorAddComponent, canActivate: [AuthGuard] },
  { path: 'vendor-detail/:id', component: VendorDetailComponent, canActivate: [AuthGuard] },
  { path: 'invoice-list', component: InvoiceListComponent, canActivate: [AuthGuard] },
  { path: 'invoice-add', component: InvoiceAddComponent, canActivate: [AuthGuard] },
  { path: 'invoice-detail/:id', component: InvoiceDetailComponent, canActivate: [AuthGuard] },
  { path: 'order-list/:type', component: OrderListComponent, canActivate: [AuthGuard] },
  { path: 'order-detail/:id', component: OrderDetailComponent, canActivate: [AuthGuard] },
  { path: 'order-edit/:id', component: OrderAddComponent, canActivate: [AuthGuard] },
  { path: 'order-add', component: OrderAddComponent, canActivate: [AuthGuard] },
  { path: 'purchase-order-list', component: PurchaseOrderListComponent, canActivate: [AuthGuard] },
  { path: 'purchase-order-detail/:id', component: PurchaseOrderDetailComponent, canActivate: [AuthGuard] },
  { path: 'purchase-order-add', component: PurchaseOrderAddComponent, canActivate: [AuthGuard] },
  { path: 'user-list', component: UsersListComponent, canActivate: [AuthGuard] },
  { path: 'users-add', component: UsersAddComponent, canActivate: [AuthGuard] },
  { path: 'users-edit/:id', component: UsersAddComponent, canActivate: [AuthGuard] },
  { path: 'role-list', component: RoleListComponent, canActivate: [AuthGuard] },
  { path: 'role-add', component: RoleAddComponent, canActivate: [AuthGuard] },
  { path: 'role-edit/:id', component: RoleAddComponent, canActivate: [AuthGuard] },
  { path: 'stock-report', component: StockReportComponent, canActivate: [AuthGuard] },
  { path: 'sales-report', component: SalesReportComponent, canActivate: [AuthGuard] },
  { path: 'product-category-stock-report', component: ProductGroupStockReportComponent, canActivate: [AuthGuard] },
  { path: 'skeleton-list', component: SkeletonListComponent, canActivate: [AuthGuard] },
  { path: 'skeleton-add', component: SkeletonAddComponent, canActivate: [AuthGuard] },
  { path: 'skeleton-detail', component: SkeletonDetailComponent, canActivate: [AuthGuard] },
  { path: 'product-discount', component: ProductDiscountComponent, canActivate: [AuthGuard] },
  { path: 'payment-list', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'attendance-list', component: AttandanceListComponent, canActivate: [AuthGuard] },
  { path: 'attendance-detail/:id', component: AttendanceDetailComponent },

  { path: 'checkin-list', component: CheckInListComponent, canActivate: [AuthGuard] },
  { path: 'leave-list', component: LeaveListComponent, canActivate: [AuthGuard] },
  { path: 'travel-list', component: TravelListComponent, canActivate: [AuthGuard] },
  { path: 'travel-add', component: TravelAddComponent, canActivate: [AuthGuard] },
  { path: 'travel-detail/:id', component: TravelDetailComponent, canActivate: [AuthGuard] },
  { path: 'expense-list', component: ExpenseListComponent, canActivate: [AuthGuard] },
  { path: 'expense-add', component: ExpenseAddComponent, canActivate: [AuthGuard] },
  { path: 'expense-detail/:id', component: ExpenseDetailComponent, canActivate: [AuthGuard] },
  { path: 'announcement-list', component: ExpenseDetailComponent, canActivate: [AuthGuard] },
  { path: 'announcement-add', component: AnnouncementAddComponent, canActivate: [AuthGuard] },
  { path: 'site-list', component: SiteListComponent, canActivate: [AuthGuard] },
  { path: 'site-detail/:id', component: siteDetailComponent, canActivate: [AuthGuard] },
  { path: 'feedback-list', component: FeedBackListComponent, canActivate: [AuthGuard] },
  { path: 'gift-list', component: GiftListComponent, canActivate: [AuthGuard] },
  { path: 'gift-edit/:id', component: GiftGalleryAddComponent, canActivate: [AuthGuard] },
  { path: 'gift-add', component: GiftGalleryAddComponent },
  { path: 'gift-detail/:id', component: GiftDetailComponent, canActivate: [AuthGuard] },
  { path: 'video-list', component: VideoListComponent, canActivate: [AuthGuard] },
  { path: 'offer-list', component: OfferListComponent, canActivate: [AuthGuard] },
  { path: 'offer-add', component: OfferAddComponent, canActivate: [AuthGuard] },
  { path: 'offer-edit/:id', component: OfferAddComponent, canActivate: [AuthGuard] },
  { path: 'offer-detail/:id', component: OfferDetailComponent, canActivate: [AuthGuard] },
  { path: 'faq-list', component: FaqListComponent, canActivate: [AuthGuard] },
  { path: 'faq-add', component: FaqAddComponent, canActivate: [AuthGuard] },
  { path: 'faq-edit/:id', component: FaqAddComponent, canActivate: [AuthGuard] },

  { path: 'content-list', component: ContentListComponent, canActivate: [AuthGuard] },
  { path: 'content-add', component: ContentAddComponent, canActivate: [AuthGuard] },
  { path: 'content-edit/:id', component: ContentAddComponent, canActivate: [AuthGuard] },

  { path: 'news-list', component: NewsListComponent, canActivate: [AuthGuard] },
  { path: 'news-add', component: NewsAddComponent, canActivate: [AuthGuard] },
  { path: 'news-edit/:id', component: NewsAddComponent, canActivate: [AuthGuard] },

  { path: 'referral-list', component: ReferralListComponent, canActivate: [AuthGuard] },

  { path: 'lead-list/:type', component: LeadListComponent, canActivate: [AuthGuard] },
  { path: 'lead-add/:type', component: LeadAddComponent, canActivate: [AuthGuard] },
  { path: 'lead-edit/:id', component: LeadAddComponent, canActivate: [AuthGuard] },


  { path: 'banner-list', component: BannerListComponent, canActivate: [AuthGuard] },
  { path: 'banner-add', component: BannerAddComponent, canActivate: [AuthGuard] },
  { path: 'banner-edit/:id', component: BannerAddComponent, canActivate: [AuthGuard] },

  { path: 'dealer-basic/:id', component: DealerBasicComponent, canActivate: [AuthGuard] },
  { path: 'dealer-followups/:id', component: DealerFollowupListComponent, canActivate: [AuthGuard] },
  { path: 'dealer-checkins/:id', component: DealerCheckinListComponent, canActivate: [AuthGuard] },
  { path: 'dealer-redeem/:id', component: DealerRedeemListComponent, canActivate: [AuthGuard] },
  { path: 'dealer-coupon/:id', component: DealerCouponListComponent, canActivate: [AuthGuard] },
  { path: 'dealer-points-list/:id', component: DealerPointsListComponent, canActivate: [AuthGuard] },


  { path: 'redeem-list', component: RedeemReqListComponent },
  { path: 'redeem-detail/:id', component: RedeemDetailComponent },
  { path: 'coupon-code-list', component: CuponCodeListComponent },
  { path: 'coupon-add', component: CouponAddComponent },


  { path: 'notification-list', component: NotificationsListComponent, canActivate: [AuthGuard] },
  { path: 'notification-detail/:code', component: NotificationDetailComponent, canActivate: [AuthGuard] },
  { path: 'notification-add', component: NotificationsAddComponent, canActivate: [AuthGuard] },
  { path: 'temp-register-list', component: TempUsersListComponent, canActivate: [AuthGuard] },

  { path: 'sales-user-add', component: UserAddComponent },
  { path: 'sales-user-edit/:id', component: UserAddComponent },
  { path: 'sales-user-list', component: UserListComponent },
  { path: 'sales-user-detail/:id', component: UserDetailComponent },
  { path: 'us-target-list/:id', component: UsTargetListComponent },
  { path: 'us-lead-list/:id', component: UsLeadListComponent },
  { path: 'us-distributor-list/:id', component: UsDistributorsLisComponent },
  { path: 'us-retailer-list/:id', component: UsDistributorsLisComponent },
  { path: 'us-followup-list/:id', component: UsFollowupListComponent },
  { path: 'us-checkin-list/:id', component: UsCheckinsListComponent },
  { path: 'us-primary-list/:id', component: UsPrimaryOrderListComponent },
  { path: 'us-secondary-list/:id', component: UsSecondaryOrderListComponent },
  { path: 'us-payment-list/:id', component: UsPaymentListComponent },
  { path: 'us-travel-list/:id', component: UsTravelListComponent },
  { path: 'us-expense-list/:id', component: UsExpenseListComponent },
  { path: 'us-leave-list/:id', component: UsLeaveListComponent },
  { path: 'us-point-list/:id', component: UsPointsListComponent },
  { path: 'dr-retailer-list/:id', component: DrDistributorsLisComponent },

  { path: 'followup-list', component: FollowupListComponent },
  { path: 'pop-gift-list', component: PopListComponent },
  { path: 'pop-add', component: PopAddComponent },
  { path: 'pop-edit/:id', component: PopAddComponent },
  { path: 'pop-detail/:id', component: PopDetailComponent },
  { path: 'enquiry-list', component: EnquiryListComponent },

  { path: 'holiday-list', component: HolidayListComponent },
  { path: 'holiday-edit/:id', component: HolidayAddComponent },
  { path: 'holiday-add', component: HolidayAddComponent },

  { path: 'event-plan-list', component: EventListComponent },
  { path: 'event-plan-detail/:id', component: EventDetailComponent },
  { path: 'allowance-list', component: AllowanceListComponent },

  { path: 'live-track', component: LiveTrackComponent, canActivate: [AuthGuard] },
  { path: 'live-track-detail/:id/:date', component: LiveTrackDetailComponent, canActivate: [AuthGuard] },
  { path: 'beat-plan-add', component: BeatPlanAddComponent, canActivate: [AuthGuard] },
  { path: 'beat-plan-list', component: BeatPlanListComponent, canActivate: [AuthGuard] },
  { path: 'beat-plan-edit/:id', component: BeatPlanAddComponent, canActivate: [AuthGuard] },
  { path: 'beat-plan-detail/:id', component: BeatPlanAddComponent, canActivate: [AuthGuard] }


  // { path: 'lead-list/:type', component: DrListComponent, canActivate: [AuthGuard]},
  // { path: 'lead-add/:type', component: DrAddComponent, canActivate: [AuthGuard]},
  // { path: 'lead-detail/:type/:id', component: DrDetailComponent, canActivate: [AuthGuard]},
  // { path: 'lead-brands/:type/:id', component: BrandAssignedListComponent, canActivate: [AuthGuard]},
  // { path: 'lead-orders/:type/:id', component: DrOrdersComponent, canActivate: [AuthGuard]},
  // { path: 'lead-product-discounts/:type/:id', component: DrProductDiscountsComponent, canActivate: [AuthGuard]},
  // { path: 'lead-invoices/:type/:id', component: DrInvoicesComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
