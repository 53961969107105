import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html'
})
export class ProductAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
    this.getData()
  }
  data:any={}
  filteredData:any={};
  getData(){
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_category').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.categoryData = resp['result'].data        ;
        this.filteredData.categoryData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
    
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/brand').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.seriesData = resp['result'].data        ;
        this.filteredData.seriesData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
    
    
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_unit').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.unitData = resp['result'].data        ;
        this.filteredData.unitData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_group').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.groupData = resp['result'].data        ;
        this.filteredData.groupData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_size').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.sizeData = resp['result'].data;
        this.filteredData.sizeData = resp['result'].data        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
    // this.db.postReq({start:0,limit:1000,filter:{}},'master/product_color').subscribe(resp=>{
    //   if(resp['status'] == 'success'){
    //     this.data.colorData = resp['result'].data;
    //     this.filteredData.colorData = resp['result'].data        
    //   }else{
    //     if(resp['message']=='Invalid token'){
    //       this.db.sessionExpire();
    //       return;
    //     }
    //     this.db.presentAlert(resp['status'],resp['message'])
    //   }
    // })
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_color').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.data.colorData = resp['result'].data;
        this.filteredData.colorData = resp['result'].data        

      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
    
  }
  
  productImage:any=[]
  changeListener($event): void {
    console.log($event);
    this.form.controls['image_path_loading'].setValue(true);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          // this.form.controls['image_path'].setValue(this.productImage);
          this.form.value.image_path.push(resp['response'].fileName)
         console.log("image",this.form.value.image_path)

        //  this.form.controls['image_path'].setValue(resp['response'].fileName);
         this.form.controls['image_path_loading'].setValue(false);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
   
  
  
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'product_id':id},'master/product_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){

        this.formsInit(resp['data']['detail']);
        // this.productImage = resp['data'].detail.productImageDetail[0].filename
        // console.log("productdetail",this.productImage)
        // this.form.value.image_path=resp['data']['detail'].productImageDetail

        // console.log("image",this.form.value.image_path)
        // console.log(resp['data']['detail'].productImageDetail,'image data');
        
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }

  removeScheme(index){
    this.form.value.image_path.splice(index,1);
  }
  form: FormGroup;
  
  formsInit(data:any) {
    console.log("formdata",data.product_image )
    this.form = this.db.formBuilder.group({
      image_path: [data.product_image ? data.product_image : null ],
      image: [data.product_image ? data.product_image : null , []],
      image_path_loading:[false],
      product_code: [data.product_code ? data.product_code : null , [Validators.required]],
      product_color: [data.product_color ? data.product_color : null , []],
      product_unit: [data.unit ? data.unit : null],
      description: [data.description ? data.description : null , []],
      size: [data.size ? data.size : null , []],//Validators.required
      hsn_code: [data.hsn_code ? data.hsn_code : null , []],
      product_name: [data.product_name ? data.product_name : null ,[Validators.required]],
      product_category: [data.product_category ? data.product_category : null ,[Validators.required]],
      // sale_price: [data.sale_price ? data.sale_price : null ,[Validators.required]],
      retailer_point: [data.retailer_point ? data.retailer_point : null ,[]],
      distributor_point: [data.distributor_point ? data.distributor_point : null ,[]],
      sales_user_point: [data.sales_user_point ? data.sales_user_point : null ,[]],
      status: [data.status ? data.status.toString() : '1' , [Validators.required]],
      product_id: [data.id ? data.id : null],
      product_group: [data.product_group ? data.product_group : null ,[Validators.required]],//Validators.required
      // brand: [data.brand ? data.brand : null ,[Validators.required]],
      // divison: [data.divison ? data.divison : null, [Validators.required]] 
      
      product_series: [data.product_series ? data.product_series : null ,[Validators.required]],

      length: [data.length ? data.length : null],
      width: [data.width ? data.width : null],
      batch_code: [data.batch_code ? data.batch_code : null],
      height: [data.height ? data.height : null],
      packaging_inner: [data.packaging_inner ? data.packaging_inner : null],
      packaging_outer: [data.packaging_outer ? data.packaging_outer : null],


    });

    console.log('form -->', this.form.value);
    
  }
  onSubmit(){
    console.log("data",this.form.value)
    
   
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return
    }
    console.log("FormValues",this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/update_product' : 'master/add_product').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
