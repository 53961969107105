import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { StatusModelComponent } from '../../leads/status-model/status-model.component';
import { DatabaseService } from '../../_services/database.service';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
// import { PaymentStatusModelComponent } from 'src/app/SFM_/payment/payment-status-model/payment-status-model.component';

@Component({
  selector: 'app-cupon-code-list',
  templateUrl: './cupon-code-list.component.html'
})
export class CuponCodeListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  checkModulePermission:any = {};
  minDate:any
  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public activatedroute: ActivatedRoute,
    ) {  
    this.minDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    }
    ngOnInit() {
      this.filter.gift_status='';
      this.filter.redeem_status = '';
      this.getCounters();
      this.getData();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    }
    tabActiveType:any={};
    tabActive:any='All'
    
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      this.filter.statusName = this.tabActive == 'All' ? '' : this.tabActive;
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0)
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'coupon/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    counters:any=[];
    getCounters(){
      
      this.db.presentLoader();
      this.db.postReq({},'coupon/list/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          // resp['result'].data.map(r=>{
          this.counters= resp['result'].data
          // })
          console.log(this.counters);
          // this.tabActive = resp['result'].data[0].name; 
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    openStatusDialog(data): void {
      console.log(data);
      
      const dialogRef = this.dialog.open(StatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getData();     
      });
    }
    selectAll(event){
      this.data.map(r=>{
        if(event.checked == true){
          r.checked = true;
        }else{
          r.checked = false
        }
      })
    }
    deleteAll(){
      let arr=[];
      this.data.map(async r=>{
        if(r.checked == true){
          await arr.push(r.id)
        }
      })
      if(!arr.length){
        this.db.presentAlert('Error','Please select items first');   
        return; 
      }
      swal({
        title: "Are you sure?",
        text: "You want to delete this",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          
          this.db.postReq({couponIdArray:arr},'coupon/delete_multiple').subscribe(resp=>{
            if(resp['status'] == 'success'){
              this.getCounters();
              swal("Deleted", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    downloadExcel(){
      if(!this.filter.date_from && !this.filter.date_to){
        this.db.presentAlert('Alert','Please select date from date to first');
        return;
      }
      this.dataNotFound = false;
      this.reqSent = false;
      this.filter.statusName = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'coupon/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
      
    }
    reopen(data){
      swal({
        title: "Are you sure?",
        text: "You want to reopen!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.coupon_id = data.encrypt_id;
          data.customer_id = data.cust_encrypt_id
          // data.point = data.cust_type_id == 4 ? data.architect_point;
          this.db.postReq(data,'coupon/reopen').subscribe(resp=>{
            if(resp['status'] == 'success'){
              this.getData();
              swal("Deleted", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
  }
  
  
  
  