import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import swal from 'sweetalert';
import { DatabaseService } from '../_services/database.service';


import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d.src';
// import drilldown from 'highcharts/modules/drilldown.src.js';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let drilldown = require('highcharts/modules/drilldown.src.js');
highcharts3D(Highcharts);
drilldown(Highcharts)
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-dashboard-lms',
  templateUrl: './dashboard-lms.component.html',
  styleUrls: ['./dashboard-lms.component.scss']
})
export class DashboardLmsComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  apiLoaded: Observable<boolean>;
  graphFilters:any={}
  constructor(
    // private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { 
      this.graphFilters.carpenterBranch = {};
      this.graphData.carpenterBranch = {};
      this.graphFilters.carpenterBranch = {};
      this.graphFilters.carpenterBranch.showFilter = false;
      this.graphData.architectBranch = {};
      this.graphFilters.architectBranch = {};
      this.graphFilters.architectBranch.showFilter = false;
      this.graphFilters.leadStateWiseBarGraph = {};
      this.graphFilters.leadStateWiseBarGraph.showFilter = false;
    }
    
    ngOnInit() {
      
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();;
      this.graph_retailer_branch();
      this.graph_architect_branch();
      this.coupon_scan_data();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    
    
    graphData:any={};
    getData(){
      
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      if(this.filter.date_from) this.filter.date_from =  moment(new Date (this.filter.date_from)).format('YYYY-MM-DD')
      if(this.filter.date_to) this.filter.date_to =  moment(new Date (this.filter.date_to)).format('YYYY-MM-DD')
      this.db.presentLoader();
      this.db.postReq(reqData,'dashboard/lms').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          this.data.countData = resp['result'].countData;
          console.log(this.data);
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    graph_retailer_branch(){
      if(this.graphFilters.carpenterBranch.date_from) this.graphFilters.carpenterBranch.date_from =  moment(new Date (this.graphFilters.carpenterBranch.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.carpenterBranch.date_to) this.graphFilters.carpenterBranch.date_to =  moment(new Date (this.graphFilters.carpenterBranch.date_to)).format('YYYY-MM-DD')
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.graphFilters.carpenterBranch
      }
      this.db.postReq(reqData,'/dashboard/lms/graph_retailer_state').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          let carpenterBranch=[];
          resp['result'].carpenterBranchWise.count.map((r,i)=>{
            carpenterBranch.push({y:r,name:resp['result'].carpenterBranchWise.branchList[i]});
          })
          console.log(carpenterBranch);
          
          this.graphData.carpenterBranch = {
            chart: {
              type: 'pie',
              options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
              }
            },
            title: {
              text: "State Wise Retailers"
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                depth: 35,  
                dataLabels: {
                  enabled: true,
                  format: '{point.name}'
                }
              }
            },
            series: [{
              name: 'Sales',
              data: carpenterBranch
            }],
            
          }
          Highcharts.chart('carpenterBranch-container', this.graphData.carpenterBranch);
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
      
    }
    graph_lead_state(){
      
    }
    graph_architect_branch(){
      if(this.graphFilters.architectBranch.date_from) this.graphFilters.architectBranch.date_from =  moment(new Date (this.graphFilters.architectBranch.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.architectBranch.date_to) this.graphFilters.architectBranch.date_to =  moment(new Date (this.graphFilters.architectBranch.date_to)).format('YYYY-MM-DD')
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.graphFilters.architectBranch
      }
      this.db.postReq(reqData,'/dashboard/lms/graph_distributor_state').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          let architectBranch=[];
          resp['result'].architectBranchWise.count.map((r,i)=>{
            architectBranch.push({y:r,name:resp['result'].architectBranchWise.branchList[i]});
          })
          console.log(architectBranch);
          
          this.graphData.architectBranch = {
            chart: {
              type: 'pie',
              options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
              }
            },
            title: {
              text: "State Wise Distributor"
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                depth: 35,  
                dataLabels: {
                  enabled: true,
                  format: '{point.name}'
                }
              }
            },
            series: [{
              name: 'Sales',
              data: architectBranch
            }],
            
          }
          Highcharts.chart('architectBranch-container', this.graphData.architectBranch);
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
      
    }
    

    coupon_scan_data(){
      if(this.graphFilters.leadStateWiseBarGraph.date_from) this.graphFilters.leadStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.leadStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.leadStateWiseBarGraph.date_to) this.graphFilters.leadStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.leadStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.graphFilters.leadStateWiseBarGraph
      }
      this.db.postReq(reqData,'dashboard/sfa/graph_lead_state').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.graphData.leadStateWise = {
            chart: {
              type: "column",
              options3d: {
                enabled: true,
                alpha: 0,
                beta: 3,
                depth: 51,
                viewDistance: 25
              }
            },
            title: {
              text: "Last 15 Days Coupon Scanned"
            },
            xAxis: {
              categories: resp['result'].leadStateWiseBarGraph.stateList
            },
            yAxis: {
              title: {
                text: "Number of Leads"
              }
            },
            series: [
              {
                name: "Last 15 Days Coupon Scanned",
                data: resp['result'].leadStateWiseBarGraph.count,
                colorByPoint: true
              }
            ]
          }
          Highcharts.chart('leadStateWise-container', this.graphData.leadStateWise);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }

    backClicked() {
      // this._location.back();
    }
    goToUserDetail(data){
      console.log(data);
      this.db.router.navigateByUrl('sales-user-detail/'+data.user_encrypt_id)
    }
    goToDrDetail(data){
      if(data.is_lead == '1')this.db.router.navigateByUrl('dr-detail/'+data.customer_encrypt_id)
      else this.db.router.navigateByUrl('dealer-basic/'+data.customer_encrypt_id)
    }
  }
  