import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import { MatOption, MatSelect } from '@angular/material';




@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html'
})
export class UsersAddComponent implements OnInit {
  @ViewChild('select', {static: false}) select : MatSelect
 
  moduleData: any = [];
  dataNotFound = false
  reqSent = false
  constructor(public actRoute: ActivatedRoute, private _location: Location, public db: DatabaseService) { }

  ngOnInit() {
    this.getModuleData()
    this.getRoleData()
  }
  getModuleData() {
    this.db.presentLoader();

    this.db.postReq({}, 'master/module_list').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.moduleData = resp['result'].data
        this.moduleData.map(r => {
          r.add = false;
          r.edit = false;
          r.delete = false;
          r.view = false;
        })


        if (this.actRoute.snapshot.params.id) {
          this.getDetail(this.actRoute.snapshot.params.id)
        }
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })

  }
  allSelected = false;
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

    console.log(this.form.working_state);

  }
  selectAll: any = {};
  selectAllHandler(control, event) {
    console.log(event);
    this.moduleData.map(r => {
      r[control] = event;
    })
  }
  getDetail(id) {
    this.db.presentLoader();

    this.db.postReq({ 'user_id': id }, 'master/system_user/detail').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.form = resp['data'].detail;
        this.form.user_id = this.form.id
        this.form.status = this.form.status.toString();
        this.moduleData.map(row => {
          resp['data'].permissionData.map(r => {
            if (row.id == r.module_id) {
              row.add = r.add == 1 ? true : false;
              row.edit = r.edit == 1 ? true : false;
              row.delete = r.delete == 1 ? true : false;
              row.view = r.view == 1 ? true : false;
              row.permission_id = r.id;
            }
          })
        })
        console.log(this.moduleData);

      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })

  }
  backClicked() {
    this._location.back();
  }
  roleData: any = [];
  // dataNotFound=false
  getRoleData() {
    let reqData = {
      limit: 30000,
      start: 0,
      filter: {
        "for_system_user": 1
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'master/role_list').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.roleData = resp['result'].data;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  form: any = {};
  formSubmitted: any = false;
  userData: any = {};
  onSubmit(myForm: any) {
    var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/

    if (!emailpattern.test(this.form.email)) {
      this.db.presentAlert('Error', 'Please enter a valid email!');
      return;
    }
    var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    if (!mobilepattern.test(this.form.mobile_no)) {
      this.db.presentAlert('Error', 'Please enter a valid mobile!');
      return;
    }
    let moduleData = this.moduleData;
    moduleData.map(r => {
      r.add = r.add ? 1 : 0;
      r.edit = r.edit ? 1 : 0;
      r.view = r.view ? 1 : 0;
      r.delete = r.delete ? 1 : 0;
      r.module_id = r.id
    })
    // console.log(this.form,this.moduleData);return;
    this.formSubmitted = true;
    let reqData = this.form
    reqData.userPermission = this.moduleData
    this.db.presentLoader();
    this.db.postReq(reqData, this.actRoute.snapshot.params.id ? 'master/system_user/update' : 'master/system_user/add').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.db.successAlert('Success', resp['message']);
        this.backClicked();
        // myForm.resetForm();
        // this.formSubmitted = false;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })

  }

}
